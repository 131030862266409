<template>
  <div id="subpage-content">
    <div id="show">
      <CompanyDrawer />
    </div>
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.NavbarAboutCompany") }}</h1>
        <div id="about-text" v-html="$t('message.AboutCompanyText1')"></div>
      </v-card>
    </div>
  </div>
</template>

<script>
import CompanyDrawer from "../../../views/Navigations/Company/CompanyDrawer";
export default {
  components: {
    CompanyDrawer,
  },
};
</script>
<style>
#history-card {
  display: flex;
  margin-top: 20px;
}
#year {
  padding: 10px;
  display: flex;
  align-items: center;
  width: 15%;
  font-size: 30px;
  font-weight: 500;
  color: #00569c;
  background-color: rgb(247, 247, 247);
}
@media (max-width: 1500px) {
  #content-wrapper {
    flex-direction: column;
  }
  #about {
    width: 100%;
  }
  #news {
    padding-top: 20px;
    width: 100%;
  }
  /* #show {
    display: none;
  } */
}
@media (max-width: 734px) {
  #history-card {
    flex-direction: column;
  }
  #history-card-text {
    width: 100%;
  }
  #year {
    width: 100%;
  }
}
</style>
